import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const Regler2021Page = () => {
  return (
    <Layout pageTitle="Ovve-regler tills 2021">
      <Link to="/regler">
        <div className='flex-none inline-block mb-4 px-4 py-3 rounded-3xl bg-gray-200 hover:bg-gray-100'>Nuvarande regler</div>
      </Link>
      <div className='max-w-lg'>
        <article className='text_page'>
          <h1>§1 Bytesdelar</h1>
          <p>Ett vanligt studentikost nöje bland många studenter är att sprätta lös och byta vissa delar av sin overall. De olika delarna har olika betydelser.</p>

          <h2>§1.1 Krage</h2>
          <p>Att byta sin krage med någon indikerar att man har, eller har haft, ett stadit förhållande med den person var krage du bytt till dig.</p>
          
          <h2>§1.2 Bakficka</h2>
          <p>Att byta en bakficka med någon indikerar att du idkat samlag med den person vars bakficka du bytt till dig.</p>
          
          <h2>§1.3 Ärmar/Byxben</h2>
          <p>Har ingen egentlig betydelse (mer än att man är vänner). Folk byter dock ofta dessa delar.</p>
          
          <h2>§1.4 Knappar</h2>
          <p>Bytes vid hångel med annan overall (lite osmidig om det är INFOcest)</p>
          
          <h2>§1.5 Tumstockficka</h2>
          <p>Att byta denna indikerar på att analsex har utförts med den person vars tumstocksficka du bytt till dig.</p>
          
          <h2>§1.6 Bröstficka</h2>
          <p>Att byta denna indikerar på att oralsex har utförts med den person vars bröstficka du bytt till dig.</p>
          
          <h2>§1.7 Bröstficka lock (Fristads, Höger)</h2>
          <p>indikerar att du förlorat din oskuld i Tradecenter med den person vars lock du bytt till dig.</p>
          
          <h2>§1.8 Bröstficka lock vänster</h2>
          <p>indikerar att du utbytt kroppsvätskor med den person vars lock du bytt till dig.</p>
          
          <h2>§1.9 Gylftyg</h2>
          <p>indikerar att en könssjukdom har utbytts mellan dig och den person vars gylftyg du bytt till dig.</p>
          
          <h2>§2 Ovvedjur</h2>
          <p>Djur av något slag, oftast mjukdjur som pryder ovven. Dessa ska förtjänas för att få sättas på ovven</p>
          
          <h2>§2.1 Vänster axel</h2>
          <p>Sillen förtjänar man genom att sitta i sexmesteriets styrelse. Denna sill pryder vänster axel på overallen.</p>
          
          <h2>§2.2 Djur på höger axel</h2>
          <p>Där sätts det mjukdjur som du och den person som haft sex i Tradecenter utbyter. (Att ”aktivitet” har ägt rum i Tradecenter skall bevisas på något sätt)</p>
          
          <h1>§3 Sanitet</h1>
          <p>Existerar icke. Tvätt av overallen får endast ske i följade scenarion:</p>
          
          <h2>§3.1</h2>
          <p>Ägaren befinner sig i denna.</p>
          
          <h2>§3.2</h2>
          <p>Någon annans illaluktande kroppsvätskor som mot din (och förhoppningsvis även dennes) vilja har donerats till din i övrigt frisk-sommar-vind-doftande overall.</p>
        </article>
      </div>
    </Layout>
  )
}

export default Regler2021Page